import { createGlobalStyle } from "styled-components";

import { gray900, white } from "./colors";

const GlobalStyle = createGlobalStyle`
  :root {
    --gray900: ${gray900};
    --white: ${white};
  }

  html {
    height: 100%;
    box-sizing: border-box;
    font-size: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // vh issues on mobile safari
    // other work arounds but this is fine 😒
    &,
    & > div,
    & > div > div {
      height: inherit;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;

export default GlobalStyle;
