import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const GET_METADATA = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`;

function SEO() {
  const { site } = useStaticQuery(GET_METADATA);

  return (
    <Helmet
      title={site.siteMetadata.title}
      link={[{ rel: "shortcut icon", type: "image/png", href: `favicon.ico` }]}
      meta={[
        {
          name: "description",
          content: site.siteMetadata.description,
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
  );
}

export default SEO;
