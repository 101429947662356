import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import SEO from "components/seo";

import accounts from "constants/accounts";
import device from "constants/device";

import GlobalStyle from "styles/globalStyle";

const SC = {};

function IndexPage({ data }) {
  return (
    <>
      <SC.IndexPage>
        <SEO />

        <SC.ContentWrapper>
          <SC.AvatarWrapper>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 144">
              <circle cx="72" cy="72" r="71"></circle>
            </svg>
            <Img fixed={data.file.childImageSharp.fixed} />
          </SC.AvatarWrapper>

          <SC.Name>
            Hi, I'm <SC.StandoutText>Rohan Benstead</SC.StandoutText>
          </SC.Name>

          <SC.Role>
            Lead Software Engineer at <SC.StandoutText>Maestro</SC.StandoutText>
          </SC.Role>

          <SC.AccountList>
            <li>
              <span>{accounts.email}</span>
            </li>
            <li>
              <a href={accounts.github}>GitHub</a>
            </li>
            <li>
              <a href={accounts.linkedin}>LinkedIn</a>
            </li>
          </SC.AccountList>
        </SC.ContentWrapper>
      </SC.IndexPage>

      <GlobalStyle />
    </>
  );
}

SC.IndexPage = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  overflow-y: auto;

  color: var(--gray900);

  @supports (display: grid) {
    display: grid;
    place-items: center;
  }
`;

SC.ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

SC.AvatarWrapper = styled.div`
  @supports not (display: grid) {
    position: relative;

    & > div {
      position: absolute !important;
      top: 8px;
      left: 8px;
    }
  }

  @supports (display: grid) {
    display: grid;
    place-items: center;

    & > * {
      grid-area: 1 / 1;
    }
  }

  img {
    border-radius: 50%;
  }

  svg {
    height: 100px;
    width: 100px;
    fill: none;
    stroke: var(--gray900);
    stroke-linecap: round;
    stroke-width: 1.5px;
    stroke-dasharray: 0.5 5;
    animation: slow-spin 120s linear infinite;
  }

  @keyframes slow-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

SC.Name = styled.h1`
  font-weight: 300;
  margin-top: 2rem;
  font-size: 4.5rem;
  line-height: 4.75rem;
`;

SC.Role = styled.h3`
  font-weight: 300;
  margin-top: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
`;

SC.StandoutText = styled.span`
  font-weight: 700;
  letter-spacing: -0.03em;
`;

SC.AccountList = styled.ul`
  display: flex;
  margin-top: 1.25rem;

  li + li {
    margin-left: 1rem;
  }

  li {
    color: var(--gray900);
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      border-color: var(--gray900);
    }
  }

  @media ${device.mobile} {
    flex-direction: column;

    li + li {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
`;

export const query = graphql`
  query {
    file(relativePath: { eq: "avatar.jpg" }) {
      childImageSharp {
        fixed(width: 84, height: 84) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default IndexPage;
